<template>
  <div class="pagee">
    <div class="con">
      <div class="title">
        <p>多种类型流量的营销资源方式</p>
        <span>多元覆盖用户各类生活场景，占据用户各种碎</span>
      </div>

      <div class="box">
        <div class="arrow-left" @click="toggleFun(-1)">
          <img src="@/static/promotion/larrow.png" alt="" />
        </div>
        <div class="left">
          <div class="Img">
            <img src="@/static/promotion/phone.png" mode="cover" />
          </div>
          <div class="cardBanner">
            <ul>
              <li v-for="(item, index) in imgList" :key="index">
                <div class="Img">
                  <img :src="item.image" alt="" />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="arrow-right" @click="toggleFun(1)">
          <img src="@/static/promotion/rarrow.png" alt="" />
        </div>
        <div class="tabs">
          <div class="tablist">
            <div
              class="tab"
              v-for="(item, index) in tabList"
              :key="index"
              @click="changeTab(item.id)"
              :class="item.id == flag ? 'org' : ''"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="scon" v-show="flag == 1">
            网红头条开屏广告在今日头条App启动时进行展示，黄金曝光点位，全屏展现，强视觉冲击，可支持展示广告素材和活动页，满足客户个性化推广需求。
          </div>
          <div class="scon" v-show="flag == 2">
            网红头条信息流中穿插展现的广告形式，广告既是内容，契合信息深度阅读体验的广告形式，支持全方位展现方式。
          </div>
          <div class="scon" v-show="flag == 3">
            文章/视频详情页中展现的广告样式，详情页中的黄金位置，支持文章、长视频等多种广告样式
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      flag: 1,
      imgList: [
        {
          id: 1,
          image: require("@/static/promotion/1.png")
        },
        {
          id: 2,
          image: require("@/static/promotion/2.png")
        },
        {
          id: 3,
          image: require("@/static/promotion/3.png")
        }
      ],
      tabList: [
        {
          id: 1,
          name: "网红头条开屏"
        },
        {
          id: 2,
          name: "网红头条信息流"
        },
        {
          id: 3,
          name: "网红头条详情页"
        }
      ]
    };
  },
  computed: {
    tabShow() {
      return this.flag;
    }
  },
  methods: {
    toggleFun(p) {
      console.log(1111111111);
      this.imgList = this.imgList.map((item, index, array) => {
        if (index === array.length - 1 && p === 1) {
          item = array[0];
          console.log(item);
          if (this.flag >= 1 && this.flag < 3) this.flag++;
          else if (item.id == 3) this.flag = 1;
        } else if (index === 0 && p === -1) {
          item = array[array.length - 1];
          console.log(item);
          if (item.id >= 1 && item.id < 3) this.flag++;
          else if (item.id == 3) this.flag = 1;
        } else {
          item = array[index + p];
        }
        return item;
      });
    },
    changeTab(id) {
      this.flag = id;
    }
  }
};
</script>
<style lang="scss" scoped>
.pagee {
  background: #fff;
  .con {
    width: 1240px;
    margin: 0 auto;
    background: #fff;
    .title {
      text-align: center;
      p {
        padding-top: 54px;
        font-size: 36px;
        font-weight: 500;
        color: #262626;
      }
      span {
        margin-top: 16px;
        font-size: 16px;
        color: #999999;
        display: inline-block;
      }
    }
    .box {
      display: flex;
      margin-top: 85px;
      padding-bottom: 124px;
      .left {
        position: relative;
        .Img {
          box-shadow: 9px 25px 42px 0px rgba(0, 0, 0, 0.2);
          border-radius: 42px;
        }
        .cardBanner {
          position: absolute;
          top: 12%;
          left: -11px;
          z-index: 100;
          ul {
            display: flex;
            width: 342px;
            overflow: hidden;
            li {
              width: 342px;
              float: left;
              cursor: pointer;
            }
          }
        }
      }
      .arrow-left {
        margin-top: 21%;
        margin-right: 25px;
        cursor: pointer;
      }
      .arrow-right {
        margin-top: 21%;
        margin-left: 25px;
        cursor: pointer;
      }
      .tabs {
        margin-top: 63px;
        margin-left: 102px;
        .tablist {
          display: flex;
          border-bottom: 1px solid #e3e3e3;
          .tab {
            width: 210px;
            height: 78px;
            text-align: center;
            line-height: 78px;
            cursor: pointer;
          }
        }
        .scon {
          margin-top: 20px;
        }
      }
    }
  }
}
.org {
  border-bottom: 2px solid #df2a29;
}
</style>