<template>
  <div class="footer">
    <div class="footerBox">
      <div class="contentBox contentBoxFlex">
        <div>
          <div class="contentBoxTextImgFlex">
            <div class="contentBoxTextImg">
              <div style="font-size: 0;margin-bottom: 12px;">
                <img
                  class="contentBoxImg"
                  src="../../../static/img/home/miniPhone.png"
                  alt=""
                />
                <span class="contentBoxText">联系电话：</span>
              </div>
              <div class="footMaxTex">
                400-119-3009
              </div>
            </div>
            <div class="contentBoxTextImg">
              <div style="font-size: 0;margin-bottom: 12px;">
                <img
                  class="contentBoxImg"
                  src="../../../static/img/home/miniWeChat.png"
                  alt=""
                />
                <span class="contentBoxText">微信号：</span>
              </div>
              <div class="footMaxTex">
                sxd_0128
              </div>
            </div>
          </div>

          <div class="contentBoxTextImgFlex">
            <div class="contentBoxTextImg">
              <div style="font-size: 0;margin-bottom: 12px;">
                <img
                  class="contentBoxImg"
                  src="../../../static/img/home/minicustomer.png"
                  alt=""
                />
                <span class="contentBoxText">服务时间：</span>
              </div>
              <div class="footMaxTex">
                周一至周六 12:00-22:00
              </div>
            </div>
            <div class="contentBoxTextImg">
              <div style="font-size: 0;margin-bottom: 12px;">
                <img
                  class="contentBoxImg"
                  src="../../../static/img/home/miniMail.png"
                  alt=""
                />
                <span class="contentBoxText">联系邮箱：</span>
              </div>
              <div class="footMaxTex">
                2606499101@qq.com
              </div>
            </div>
          </div>
        </div>

        <div class="contentBoxWeChat">
          <img class="customerImg" src="/static/wanghong/kefu.jpg" alt="" />
          <div style="font-size: 12px;color: #FFFFFF;">
            扫描二维码 添加客服人员
          </div>
        </div>
      </div>
      <div class="bottomBox">
        <div class="bottomBoxText">
          豫ICP备 19039737号 中能魔力（河南）网络科技有限公司 |
          地址：洛阳市万达世纪中心21层
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "foot",
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.footer {
  height: 318px;
  width: 100%;
  background-color: #333333;
}
.footerBox {
  width: 1240px;
  margin: 0 auto;
  height: 100%;
}
.contentBox {
  height: 146px;
  padding-top: 70px;
}
.contentBoxFlex {
  display: flex;
  justify-content: space-between;
}
.contentBoxWeChat {
  text-align: center;
}
.customerImg {
  width: 120px;
  height: 120px;
  margin-bottom: 14px;
  border-radius: 4px;
}
.contentBoxTextImgFlex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.contentBoxTextImg {
  width: 300px;
}
.contentBoxTextImg:nth-child(n) {
  margin-right: 40px;
}
.contentBoxImg {
  vertical-align: middle;
  margin-right: 8px;
}
.contentBoxText {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  vertical-align: middle;
}
.footMaxTex {
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  text-indent: 26px;
}
.bottomBox {
  text-align: center;
}
.bottomBoxText {
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.3);
  margin-top: 132px;
}
</style>
